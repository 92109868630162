import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import loadable from '@loadable/component';
import { graphicDesign } from '../data/data';

const Grid = loadable(() => import('../components/grid'));
const CardWithoutImage = loadable(() => import('../components/card-without-image'));

const GraphicDesign = ({ data }) => {
  const metaData = {
    title: 'Графичен дизайн'
  };

  return (
    <Layout metaData={metaData}>
      <Grid mode="dark">
        {graphicDesign.map(page => (
          <CardWithoutImage
            key={page.id}
            title={page.title}
            bg={data[page.id] ? data[page.id].childImageSharp.fluid.src : ''}
            url={page.slug}
          />
        ))}
      </Grid>
    </Layout>
  );
};

export default GraphicDesign;

export const query = graphql`
  query graphicDesign {
    posters: file(name: { eq: "01-плакати" }) {
      ...mediumImage
    }
    archforum: file(name: { eq: "01-archforum" }) {
      ...mediumImage
    }
    calendarMondi: file(name: { eq: "01-календар-монди" }) {
      ...mediumImage
    }
    cards: file(name: { eq: "01-баба-марта" }) {
      ...mediumImage
    }
  }
`;
